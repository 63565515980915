import React from "react"
import { Link, graphql } from 'gatsby'
import Image from '../components/image-provider'

import Layout from "../components/layout"
import SEO from "../components/seo"

import EnhanceSVG from '../graphics/enhanceyourbusiness.svg'
import { ArrowDownSVG, ArrowRightSVG } from '../components/svgs'

import scrollTo from 'gatsby-plugin-smoothscroll';

const ServicesPage = ({ data, location }) => {

    const socialCardImage = data.allFile.edges[0].node.childImageSharp.resize

    return(
        <Layout>
            <SEO
                title="Services"
                description="Chicago's one stop team of developers for all your web and digtal solutions."
                image={socialCardImage}
                pathname={location.pathname}
            />
            <Image
                fileName="restaurantdining.jpg"
                className="h-screen min-height"
                alt="Photograph of the inside of a classic urban diner."
            />

            <div className="absolute top-0 left-0 w-full h-screen min-height border-60 border-dkpurple border-opacity-80 border-t-0 laptop:border-b-80">
                <div className="max-viewport h-full mx-auto flex items-center">
                    <img
                        src={EnhanceSVG}
                        alt="Enhance your business"
                        className="w-5/6 mx-auto laptop:ml-24"
                        style={{ maxWidth: '420px' }}
                    />
                </div>
                <button
                    className="relative bottom-32 text-white block w-32 mx-auto laptop:cursor-pointer"
                    onClick={() => scrollTo('#services-overview')}
                >
                    <h2 className="text-sm text-center">View our services</h2>
                    <ArrowDownSVG className="fill-current w-8 mx-auto animate-bounce mt-2" />
                </button>
            </div>
            
            <div id="services-overview" className="w-full bg-gandalfgray pb-16 pt-16 laptop:pt-24">
                <div className="max-viewport mx-auto">
                    <div className="w-full flex flex-wrap"> 

                        <div className="w-full laptop:w-2/5 laptop:px-4">
                            <div className="bg-ltblue rounded-md w-3/4 mx-auto p-4 text-white text-center laptop:w-full laptop:p-12" style={{ maxWidth: '576px' }}>
                                <h2>Web Development</h2>
                                <p className="mt-4 text-sm">Secure, scalable, technology for the future.</p>
                            </div>
                        </div>

                        <div className="w-full px-6 text-sm mt-8 laptop:w-3/5 laptop:mt-0">
                            <h2 className="text-xl opacity-70">User Experience / User Interface</h2>
                            <p className="mt-2">UX/UI: Custom-built websites from scratch. No templates. We tailor the design to showcase your brand image. Your business is unique. Help yourself stand out from the competition where it matters.</p>
                            <h2 className="text-xl mt-8 opacity-70">Always Responsive Design</h2>
                            <p className="mt-2">Responsive Web Development: Mobile friendly designs that look amazing on all devices. Built for phones, tablets, laptops, and desktops.</p>
                        </div>

                        <div className="w-full px-6 text-sm mt-8 laptop:w-1/3 laptop:mt-12">
                            <h2 className="text-xl opacity-70">Search Engine Optimization</h2>
                            <p className="mt-2">SEO: Implement search engine optimization to organically grow your website’s user base. We use google search tools and analytics to test, track, and modify your website to ensure your links are accurately and reliably shown as a top result  in users’ searches.</p>
                        </div>

                        <div className="w-full px-6 text-sm mt-8 laptop:w-1/3 laptop:mt-12">
                            <h2 className="text-xl opacity-70">E-commerce Business</h2>
                            <p className="mt-2">Ecommerce Solutions: Build an online store where you can sell your physical products or digital content. Integrated order fulfillment, inventory management, and shipping label printing services through an easy to use dashboard.</p>
                        </div>

                        <div className="w-full px-6 text-sm mt-8 laptop:w-1/3 laptop:mt-12">
                            <h2 className="text-xl opacity-70">Content Management System</h2>
                            <p className="mt-2">CMS: Powerful yet intuitive Content Management Systems that let you easily manage your website's content. Our custom CMS empowers non-technical users to update and add web content instantly. We offer thorough, reliable CMS training for you and your team.</p>
                        </div>                  
                    </div>        
                </div>      
            </div>

            <div className="w-full pb-16 pt-16 laptop:pt-24">
                <div className="max-viewport mx-auto">
                    <div className="w-full flex flex-wrap">

                        <div className="hidden px-6 text-sm laptop:w-3/5 laptop:block">
                            <h2 className="text-xl opacity-70">Social Media</h2>
                            <p className="mt-2">Maintain and grow your social media following. Leverage the power of social media and its ability to connect people, share relevant information faster, boost customer service, and increase visibility for your business. Use a combination of free and paid campaigns to accumulate local, national, and global audiences. Direct people to your brick-and-mortar store or website with calls to action.</p>
                        </div>
                    
                        <div className="w-full laptop:w-2/5 laptop:px-4">
                            <div className="bg-magenta rounded-md w-3/4 mx-auto p-4 text-white text-center laptop:w-full laptop:p-12" style={{ maxWidth: '576px' }}>
                                <h2>Digital Marketing</h2>
                                <p className="mt-4 text-sm">Amplify your online presence.</p>
                            </div>
                        </div>

                        <div className="w-full px-6 text-sm mt-8 laptop:w-1/3 laptop:mt-12">
                            <h2 className="text-xl opacity-70">Branding</h2>
                            <p className="mt-2">Whether you need a minor update or an entirely fresh idea, we produce professional grade, unique brand images that will speak to your audience. Thumbnail images, icons, banners, logos and more.</p>
                        </div>

                        <div className="w-full px-6 text-sm mt-8 laptop:w-1/3 laptop:mt-12">
                            <h2 className="text-xl opacity-70">Print Design</h2>
                            <p className="mt-2">Restaurant menu design: Share a menu that your guests won’t forget. Drink, lunch, dinner, and catering designs available in quality prints and digital QR.</p>
                            <p className="mt-2">Brochures, flyers, gift certificates, and more.</p>
                        </div>

                        <div className="w-full px-6 text-sm mt-8 laptop:w-1/3 laptop:mt-12">
                            <h2 className="text-xl opacity-70">Reputation Management</h2>
                            <p className="mt-2">Establish a consistent and positive image across all online platforms including, Facebook, Instagram, Yelp, Twitter, and Google Business.</p>
                        </div>

                        <div className="w-full px-6 text-sm mt-8 laptop:hidden">
                            <h2 className="text-xl opacity-70">Social Media</h2>
                            <p className="mt-2">Maintain and grow your social media following. Leverage the power of social media and its ability to connect people, share relevant information faster, boost customer service, and increase visibility for your business. Use a combination of free and paid campaigns to accumulate local, national, and global audiences. Direct people to your brick-and-mortar store or website with calls to action.</p>
                        </div>

                        <div className="w-full flex justify-end mt-16 pr-6">
                        <Link
                                to="/contact/"
                                className="flex items-center hover:pointer"
                                style={{ width: '110px' }}
                            >
                                <p className="text-matteblue mr-2">Contact us</p>
                                <ArrowRightSVG className="fill-current opacity-50 bounce-right" />
                            </Link>
                        </div>

                    </div>        
                </div>
            </div>
        </Layout>
    )
}

export default ServicesPage;

export const pageQuery = graphql`
    query {
        allFile(filter: {name: {eq: "rarechicago-socialcard-services"}}) {
        edges {
            node {
            childImageSharp {
                resize(width: 1200) {
                src
                height
                width
                }
            }
            }
        }
        }
    }
`